




































































import Vue from "vue";
import { mapGetters } from "vuex";
import { Loan } from "@/types";
import { apiAxios } from "@/store/apiAxios";
  
export default Vue.extend({
  name: "ExpirationList",
  props: { loanId: { type: Number, required: true } },
  data: () => ({
    isLoading: false,
  }),
  watch: {
    'loan.bankstatement' :{
      handler: function(val) {
        if (val.length > 0) {
          this.emptyBankstatement = false;
        } else {
          this.emptyBankstatement = true;
        }
      },
      immediate: true,
    }
  },
  methods: {
    renewConsent(requisitionId: number, userId: number, bankId: number) {
      apiAxios.get(`api/bankstatement/renew/${requisitionId}/${userId}/${bankId}/`).then(()=> {
        this.isLoading = false;
        this.$router.push('/').then(() => window.location.reload());
      });
    },
    setFallbackImage(event: any) {
      event.target.src = '';
    }
  },
  computed: {
    ...mapGetters({
      loans: "loans/getLoans",
      accounts: "account/getAccount",
    }),
    loan(): any {
      return this.loans.filter((l: Loan) => l.ac_deal_id === this.loanId)[0];
    },
    sortedBankStatements() {
      const loan = this.loan as Loan as any;
      if(loan.bankstatement.length > 1) {
        return loan.bankstatement.slice().sort((a: any, b: any) => {
          return this.getDayLeft(a) - this.getDayLeft(b);
        });
      } else {
        return loan.bankstatement;
      }
    },
    getDayLeft() {
      return function(bankstatement: any) {
        const day = 1000 * 60 * 60 * 24;
        const today = new Date().getTime();
        const dayToDiff = new Date(bankstatement.consent_expiration).getTime();
        const differenceMs = Math.abs(dayToDiff - today);
        return Math.round(differenceMs / day);
      };
    },
    emptyBankstatement: {
      get(): boolean {
        return this.$store.getters['bankstatement/getEmptyBankstatement'];
      },

      set(value: boolean) {
        this.$store.commit('bankstatement/setEmptyBankstatement', value);
      }
    }
  },
});
